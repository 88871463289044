var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"curriculum-info",staticStyle:{"min-width":"1200px"}},[_c('div',[_c('banner-bg',{attrs:{"type":4}},[_c('div',{staticClass:"curriculum-info-base"},[_c('new-banner',{attrs:{"bannerList":_vm.bannerList,"height":600}})],1)])],1),_c('div',{staticClass:"curriculum-info-body"},[_c('div',{staticClass:"curriculum-info-title"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.curriculum.courseName))])]),_c('div',{staticClass:"tool-bar"},[_c('div',{staticClass:"search-box"},[_c('el-input',{staticClass:"box-input",attrs:{"placeholder":"请输入课程单元名称","clearable":"","size":"small"},on:{"change":_vm.searchValueList},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_c('el-button',{staticClass:"box-button box-button-search",attrs:{"type":"primary"},on:{"click":_vm.searchValueList}},[_vm._v("搜索")]),_c('el-button',{staticClass:"box-button box-button-reset",attrs:{"type":"info"},on:{"click":_vm.resetSearchValue}},[_vm._v("重置")])],1),_c('SwitchTab',{attrs:{"tabValue":_vm.modelValue,"tabList":[
              { value: true, label: '精简模式' },
              { value: false, label: '列表模式' } ]},on:{"update:tabValue":function($event){_vm.modelValue=$event},"update:tab-value":function($event){_vm.modelValue=$event},"tabClick":_vm.switchModel}})],1)]),(!_vm.curriculumList || _vm.curriculumList.length == 0)?_c('span',{staticClass:"tip-text"},[_vm._v("更多课程开放，请联系管理员")]):[(!_vm.modelValue)?_c('ul',{key:"1",staticClass:"curriculum-info-list"},_vm._l((_vm.curriculumList),function(item){return _c('li',{key:item.id,staticClass:"info-item"},[(item.unitCover)?_c('img',{attrs:{"src":item.unitCover}}):_c('img',{attrs:{"src":_vm.defaultCover}}),_c('div',{staticClass:"curriculum-img"}),_c('div',{staticClass:"curriculum-info"},[_c('p',{staticClass:"title"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.unitName))])]),(item.unitIntro)?_c('el-tooltip',{attrs:{"content":item.unitIntro,"popper-class":"curriculum-tooltip"}},[_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(item.unitIntro)+" ")])]):_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(item.unitIntro)+" ")]),_c('ul',{staticClass:"curriculum-tags"},[_vm._l((item.tags),function(tag){return _c('li',{key:tag.typeId},[_vm._v(" "+_vm._s(tag.typeName)+" ")])}),_c('li',[_vm._v(_vm._s(item.grade))]),_c('li',[_vm._v(_vm._s(item.difficulty))])],2),_c('div',{staticClass:"handle-bar"},[_c('p',{staticClass:"operation"},[_c('i',{class:[
                  'iconfont',
                  item.isCollect
                    ? 'iconyishoucang0081ef active'
                    : 'iconshoucang979EB1',
                  'can-active' ],on:{"click":function($event){return _vm.collectOrStar(item, 2)}}}),_c('i',{class:[
                  'iconfont icondianzan979EB1',
                  item.isStar
                    ? 'iconyidianzan0081ef active'
                    : 'icondianzan979EB1',
                  'can-active' ],on:{"click":function($event){return _vm.collectOrStar(item, 1)}}})]),_c('a',{class:item.studyLink ? 'button' : 'button button-diabled',attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.goCurriculum(item)}}},[_vm._v("学习课程")])])],1)])}),0):_c('ul',{key:"2",staticClass:"curriculum-info-card-list"},_vm._l((_vm.curriculumList),function(item,key){return _c('li',{key:item.id,staticClass:"list-item",style:({ 'margin-right': (key + 1) % 4 === 0 ? '0' : '25px' })},[(item.unitCover)?_c('img',{staticClass:"item-img",attrs:{"src":item.unitCover}}):_c('img',{staticClass:"item-img",attrs:{"src":_vm.defaultCover}}),_c('div',{staticClass:"item-main"},[_c('el-tooltip',{attrs:{"placement":"top","enterable":false,"content":item.unitName}},[_c('div',{staticClass:"main-title"},[_vm._v(_vm._s(item.unitName))])]),(item.unitIntro)?_c('el-tooltip',{attrs:{"placement":"top","enterable":false,"content":item.unitIntro,"popper-class":"curriculum-tooltip"}},[_c('div',{staticClass:"main-desc"},[_vm._v(_vm._s(item.unitIntro))])]):_c('div',{staticClass:"main-desc"},[_vm._v(_vm._s(item.unitIntro))]),_c('el-tooltip',{attrs:{"placement":"top","enterable":false,"content":((item.tags
            .map(function (item) { return ("[" + (item.typeName) + "]"); })
            .join()) + " [" + (item.grade) + "] [" + (item.difficulty) + "]")}},[_c('ul',{staticClass:"main-tags"},[_vm._l((item.tags),function(tag){return [(tag.typeName)?_c('li',{key:tag.typeId,staticClass:"tags-item"},[_vm._v(" "+_vm._s(tag.typeName)+" ")]):_vm._e()]}),(item.grade)?_c('li',{staticClass:"tags-item"},[_vm._v(_vm._s(item.grade))]):_vm._e(),(item.difficulty)?_c('li',{staticClass:"tags-item"},[_vm._v(_vm._s(item.difficulty))]):_vm._e()],2)]),_c('a',{class:item.studyLink ? 'button' : 'button button-diabled',attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.goCurriculum(item)}}},[_vm._v("学习课程")]),_c('p',{staticClass:"operation"},[_c('i',{class:[
                'iconfont',
                item.isCollect
                  ? 'iconyishoucang0081ef active'
                  : 'iconshoucang979EB1',
                'can-active' ],on:{"click":function($event){return _vm.collectOrStar(item, 2)}}}),_c('i',{class:[
                'iconfont icondianzan979EB1',
                item.isStar
                  ? 'iconyidianzan0081ef active'
                  : 'icondianzan979EB1',
                'can-active' ],on:{"click":function($event){return _vm.collectOrStar(item, 1)}}})])],1)])}),0),_c('div',{staticClass:"table-pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"sizes,prev, pager, next, jumper","total":_vm.queryParam.total,"current-page":_vm.queryParam.pageNo,"page-size":_vm.queryParam.pageSize,"page-sizes":_vm.modelValue ? [16, 24, 32] : [3, 5, 10, 20]},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}})],1)],_c('div',{staticClass:"fix-opts"},[_c('ul',[_c('li',{attrs:{"title":"返回赛事"},on:{"click":function($event){return _vm.$router.push('/curriculum')}}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"返回课程包","placement":"left"}},[_c('img',{attrs:{"src":require("@/assets/image/match/to-return.svg"),"alt":"返回课程包"}})])],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }