<template>
  <div class="curriculum-info" style="min-width: 1200px">
    <div>
      <banner-bg :type="4">
        <div class="curriculum-info-base">
          <new-banner :bannerList="bannerList" :height="600"></new-banner>
        </div>
      </banner-bg>
    </div>
    <div class="curriculum-info-body">
      <div class="curriculum-info-title">
        <span class="title">{{ curriculum.courseName }}</span>
      </div>
      <div class="tool-bar">
        <div class="search-box">
          <el-input
            class="box-input"
            placeholder="请输入课程单元名称"
            clearable
            size="small"
            v-model="searchValue"
            @change="searchValueList"
          />
          <el-button
            class="box-button box-button-search"
            @click="searchValueList"
            type="primary"
            >搜索</el-button
          >
          <el-button
            class="box-button box-button-reset"
            @click="resetSearchValue"
            type="info"
            >重置</el-button
          >
        </div>
        <!-- <div class="model-box" :class="{ 'model-box-active': modelValue }">
          <div class="box-desc">
            {{ modelValue ? '精简模式' : '列表模式' }}
          </div>
          <el-switch
            @change="switchModel"
            class="box-button"
            active-color="#5C9DF8"
            v-model="modelValue"
          />
        </div> -->
        <SwitchTab
              :tabValue.sync="modelValue"
              :tabList="[
                { value: true, label: '精简模式' },
                { value: false, label: '列表模式' },
              ]"
              @tabClick="switchModel"
            />
      </div>
    </div>
    <span class="tip-text" v-if="!curriculumList || curriculumList.length == 0"
      >更多课程开放，请联系管理员</span
    >
    <template v-else>
      <ul class="curriculum-info-list" v-if="!modelValue" key="1">
        <li class="info-item" v-for="item in curriculumList" :key="item.id">
          <img v-if="item.unitCover" :src="item.unitCover" />
          <img v-else :src="defaultCover" />
          <div class="curriculum-img">
            <!-- <img :src="item.unitCover" /> -->
          </div>
          <div class="curriculum-info">
            <p class="title">
              <span class="name">{{ item.unitName }}</span>
            </p>
            <el-tooltip
              v-if="item.unitIntro"
              :content="item.unitIntro"
              popper-class="curriculum-tooltip"
            >
              <div class="info">
                {{ item.unitIntro }}
              </div></el-tooltip
            >
            <div v-else class="info">
              {{ item.unitIntro }}
            </div>
            <ul class="curriculum-tags">
              <!-- <li :key="item.typeName">{{item.typeName}}</li> -->
              <li v-for="tag in item.tags" :key="tag.typeId">
                {{ tag.typeName }}
              </li>
              <li>{{ item.grade }}</li>
              <li>{{ item.difficulty }}</li>
            </ul>
            <div class="handle-bar">
              <p class="operation">
                <i
                  :class="[
                    'iconfont',
                    item.isCollect
                      ? 'iconyishoucang0081ef active'
                      : 'iconshoucang979EB1',
                    'can-active',
                  ]"
                  @click="collectOrStar(item, 2)"
                ></i>
                <i
                  :class="[
                    'iconfont icondianzan979EB1',
                    item.isStar
                      ? 'iconyidianzan0081ef active'
                      : 'icondianzan979EB1',
                    'can-active',
                  ]"
                  @click="collectOrStar(item, 1)"
                ></i>
              </p>
              <a
                :class="item.studyLink ? 'button' : 'button button-diabled'"
                href="javascript:;"
                @click="goCurriculum(item)"
                >学习课程</a
              >
            </div>
          </div>
        </li>
      </ul>
      <ul class="curriculum-info-card-list" v-else key="2">
        <li
          class="list-item"
          :style="{ 'margin-right': (key + 1) % 4 === 0 ? '0' : '25px' }"
          v-for="(item, key) in curriculumList"
          :key="item.id"
        >
          <img class="item-img" v-if="item.unitCover" :src="item.unitCover" />
          <img class="item-img" v-else :src="defaultCover" />
          <div class="item-main">
            <el-tooltip placement="top" :enterable="false" :content="item.unitName">
            <div class="main-title">{{ item.unitName }}</div>
            </el-tooltip>
            <el-tooltip
              v-if="item.unitIntro"
              placement="top" :enterable="false"
              :content="item.unitIntro"
              popper-class="curriculum-tooltip"
            >
              <div class="main-desc">{{ item.unitIntro }}</div></el-tooltip
            >
            <div v-else class="main-desc">{{ item.unitIntro }}</div>
              <el-tooltip
              placement="top" :enterable="false"
            :content="`${item.tags
              .map((item) => `[${item.typeName}]`)
              .join()} [${item.grade}] [${item.difficulty}]`"
          >
            <ul class="main-tags">
              <template v-for="tag in item.tags">
                <li class="tags-item"  :key="tag.typeId" v-if="tag.typeName">
                  {{ tag.typeName }}
                </li>
              </template>
              <li class="tags-item" v-if="item.grade">{{ item.grade }}</li>
              <li class="tags-item" v-if="item.difficulty">{{ item.difficulty }}</li>
            </ul>
              </el-tooltip>
            <a
              :class="item.studyLink ? 'button' : 'button button-diabled'"
              href="javascript:;"
              @click="goCurriculum(item)"
              >学习课程</a
            >
            <p class="operation">
              <i
                :class="[
                  'iconfont',
                  item.isCollect
                    ? 'iconyishoucang0081ef active'
                    : 'iconshoucang979EB1',
                  'can-active',
                ]"
                @click="collectOrStar(item, 2)"
              ></i>
              <i
                :class="[
                  'iconfont icondianzan979EB1',
                  item.isStar
                    ? 'iconyidianzan0081ef active'
                    : 'icondianzan979EB1',
                  'can-active',
                ]"
                @click="collectOrStar(item, 1)"
              ></i>
            </p>
          </div>
        </li>
      </ul>
      <div class="table-pagination">
        <el-pagination
          background
          layout="sizes,prev, pager, next, jumper"
          :total="queryParam.total"
          :current-page="queryParam.pageNo"
          :page-size="queryParam.pageSize"
          :page-sizes="modelValue ? [16, 24, 32] : [3, 5, 10, 20]"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </template>

    <div class="fix-opts">
      <ul>
        <li @click="$router.push('/curriculum')" title="返回赛事">
          <el-tooltip
            class="item"
            effect="dark"
            content="返回课程包"
            placement="left"
          >
            <img src="@/assets/image/match/to-return.svg" alt="返回课程包" />
          </el-tooltip>
          <!-- <span slot="title">返回赛事</span> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { courseDetail, recordCourseTimes } from '@/api/course';
import { putLikes } from '@/api/community';
import { getMyConcerns, judgePermission } from '@/api/user';
import Vue from 'vue';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import NewBanner from '@/components/NewBanner.vue';
import { getBanners } from '@/api/common';
import BannerBg from '@/components/BannerBg.vue';
import defaultCover from '@/assets/image/default-course-back.jpeg';

import SwitchTab from '@/components/SwitchTab.vue';
export default {
  name: 'Curriculum-Info',
  components: {
    NewBanner,
    BannerBg,
    SwitchTab,
  },
  data() {
    return {
      defaultCover,
      cid: this.$route.query.cid,
      curriculumList: [],
      bannerList: [],
      curriculum: {},
      queryParam: {
        courseId: this.$route.query.cid,
        pageNo: 1,
        pageSize: 16,
        total: 0,
        isPublic: 0,
      },
      searchValue: '',
      modelValue: true,
    };
  },
  mounted() {
    this.getDatas();
    this.getBannerList();
  },
  methods: {
    getBannerList() {
      let param = {
        available: 1,
        pageSize: 99,
        location: 'kc',
        platform: 'yqb',
        isPortal: '1',
      };
      getBanners(param).then((res) => {
        if (res.success) {
          this.bannerList = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleCurrentChange(val) {
      this.queryParam.pageNo = val;
      this.getDatas();
    },
    handleSizeChange(val) {
      this.queryParam.pageSize = val;
      this.getDatas();
    },
    getStarAndCollect(idList) {
      getMyConcerns({ workIds: idList.join(','), type: 'COURSE_MODULE' }).then(
        (res) => {
          if (res.success) {
            this.curriculumList.forEach((item) => {
              if (res.result[item.id] == 1) {
                item.isStar = true;
              } else if (res.result[item.id] == 2) {
                item.isCollect = true;
              } else if (res.result[item.id] == 3) {
                item.isCollect = true;
                item.isStar = true;
              }
            });
          }
        },
      );
    },
    getDatas() {
      this.queryParam.unitName = this.searchValue;
      courseDetail(this.queryParam).then((res) => {
        if (res.success) {
          this.queryParam.pageNo = res.result.courseUnits.current;
          this.queryParam.total = res.result.courseUnits.total;
          this.curriculumList = res.result.courseUnits.records.map((item) => {
            return Object.assign({}, item, {
              isCollect: false,
              isStar: false,
              // typeName: res.result.course.typeName,
              tags: res.result.course.tags,
              grade:
                res.result.course.grade && res.result.course.grade != ''
                  ? res.result.course.grade.split('/')[0]
                  : null,
              // grade: res.result.course.grade.split('/')[0],
              difficulty: res.result.course.difficulty,
            });
          });
          this.getStarAndCollect(this.curriculumList.map((item) => item.id));
          this.curriculum = res.result.course;
        }
      });
    },
    goCurriculum(item) {
      // console.log(item)
      const token = Vue.ls.get(ACCESS_TOKEN);
      if (!token)
        return this.$router.push(
          '/login?redirect_uri=' + encodeURI(location.href),
        );
      let param = {
        courseUnitId: item.id,
      };
      judgePermission(param).then((res) => {
        if (!res.success) {
          if (res.code == -1) {
            this.$alert(
              '您还没有课程，请联系学校管理员或平台为您添加课程team@eqibian.com',
              '',
              {
                confirmButtonText: '确定',
              },
            );
            // }else if(res.code == -2){
            //    this.$alert('该课程不在排课记录中!', '', {
            //     confirmButtonText: '确定',
            //   });
            // }
          } else {
            this.$message.error(res.message);
          }
        } else {
          let paramsData = {
            unitId: item.id,
            unitName: item.unitName,
            courseId: this.cid,
          };
          this.recordCourse(paramsData);
          const { studyLink, unitName } = item;
          if (studyLink) {
            const links = studyLink.split('?');
            let params = '';
            links.forEach((item, index) => {
              if (index === 0) {
                const newItem = item.replace('/', '');
                params += `?lab=${newItem}`;
              } else {
                params += `&${item}`;
              }
            });
            params += `&unitName=${item.unitName}`;
            const newLink = `${window.location.protocol}//${window.location.host}/teaching${params}`;
            const token = Vue.ls.get(ACCESS_TOKEN);
            if (!token) {
              window.location.href =
                window.location.origin +
                '/login?redirect_uri=' +
                encodeURI(location.href);
              // window.open(`${window.location.protocol}//${window.location.host}/passport/dologin?url=${encodeURIComponent(newLink)}`);
              return;
            }
            window.open(newLink);
          }
        }
      });
    },
    recordCourse(params) {
      recordCourseTimes(params).then((res) => {});
    },
    collectOrStar(item, opeType) {
      putLikes(
        { id: item.id, opeType: opeType, type: 'COURSE_MODULE' },
        opeType == 2 ? item.isCollect : item.isStar,
      ).then((res) => {
        if (res.success) {
          this.$notify({
            title: '系统提示',
            message:
              opeType == 2
                ? item.isCollect
                  ? '取消收藏成功'
                  : '收藏成功'
                : item.isStar
                ? '取消点赞成功'
                : '点赞成功',
            type: 'success',
          });
          if (opeType == 2) {
            item.isCollect = !item.isCollect;
            item.up += item.isCollect ? 1 : -1;
          } else {
            item.isStar = !item.isStar;
            item.star += item.isStar ? 1 : -1;
          }
        }
      });
    },
    resetSearchValue() {
      this.searchValue = '';
      this.queryParam.pageNo = 1;
      this.queryParam.pageSize = this.modelValue ? 16 : 10;
      this.getDatas();
    },
    switchModel(val) {
      this.modelValue = val
      this.queryParam.pageNo = 1;
      this.queryParam.pageSize = this.modelValue ? 16 : 10;
      this.getDatas();
    },
    searchValueList() {
      this.queryParam.pageNo = 1;
      this.queryParam.pageSize = this.modelValue ? 16 : 10;
      this.getDatas();
    },
  },
};
</script>

<style lang="scss" scoped>
.curriculum-info {
  width: 100%;
  .curriculum-info-base {
    width: 100%;
    height: 600px;
    margin: auto;
    background-color: #0081ef;
    background-image: url(~@/assets/image/bg.png);
    background-size: cover;
    .img {
      position: relative;
      width: 1000px;
      margin: 0 auto;
      padding-top: 30px;
      img {
        position: absolute;
        top: 0;
        width: 100%;
      }
      p.title {
        font-size: 36px;
        color: #fff;
        font-weight: bold;
        padding-left: 45px;
        line-height: 1;
      }
      p.desc {
        font-size: 20px;
        color: #fff;
        margin-top: 10px;
        padding-left: 45px;
        line-height: 1;
      }
    }
  }
  .curriculum-info-body {
    width: 1000px;
    margin: 0 auto;
    .curriculum-info-title {
      border-bottom: 1px solid #eff3f8;
      padding: 5px 0;
      margin-top: 60px;
      span {
        font-size: 16px;
        color: #9dafc8;
        vertical-align: bottom;
        line-height: 1;
        &.title {
          font-size: 30px;
          color: #333;
          margin-right: 30px;
          font-weight: bold;
        }
      }
    }
    .tool-bar {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
      .search-box {
        display: flex;
        align-items: center;
        .box-input::v-deep {
          .el-input__inner {
            font-size: 12px;
            color: #333;
            width: 200px;
            height: 30px;
            border: 1px solid #999;
            border-radius: 5px;
            &::placeholder {
              color: #999;
            }
            &:focus {
              border: 1px solid #5c9df8;
              &::placeholder {
                color: #333;
              }
            }
          }
        }
        .box-button {
          width: 50px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          margin-left: 10px;
          border: none;
          border-radius: 5px;
        }
        .box-button-search {
          background-color: #5c9df8;
          &:hover {
            background-color: #96c2ff;
          }
        }
        .box-button-reset {
          background-color: #999999;
          &:hover {
            background-color: #c9c9c9;
          }
        }
      }
      .model-box {
        display: flex;
        align-items: center;
        .box-desc {
          font-size: 14px;
          color: #999;
          margin-right: 5px;
        }
      }
      .model-box-active {
        .box-desc {
          color: #5c9df8;
        }
      }
    }
  }
  ul.curriculum-info-list {
    width: 1000px;
    margin: 20px auto 0;
    min-height: 400px;
    .info-item {
      padding: 10px 15px;
      margin-bottom: 30px;
      background-color: #eff3f8;
      border-radius: 10px;
      height: 200px;
      position: relative;
      overflow: hidden;
      transition: all 0.3s;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      }
      & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .curriculum-img {
        width: 260px;
        height: 180px;
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
        img {
          width: 100%;
        }
      }
      .curriculum-info {
        position: absolute;
        top: 0;
        right: 0;
        width: 500px;
        height: 100%;
        padding-right: 24px;
        p.title {
          color: #fff;
          margin: 16px 0;
          span {
            &.name {
              font-size: 16px;
              line-height: 22px;
              font-weight: bold;
            }
          }
        }
        .info {
          width: 100%;
          height: 32px;
          line-height: 16px;
          font-size: 12px;
          color: #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          word-break: break-all;
          /*！autoprefixer: off */
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          /*！autoprefixer: on */
          margin-bottom: 20px;
        }
        .curriculum-tags {
          margin-bottom: 10px;
          display: flex;
          li {
            height: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 10px;
            margin-right: 10px;
            border-radius: 304px;
            color: #5c9df8;
            font-size: 12px;
            background-color: #fff;
          }
        }
        .handle-bar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .operation {
            display: flex;
            align-items: center;
            .iconfont {
              font-size: 18px;
              color: #fff;
              margin-right: 50px;
            }
          }
          .button {
            width: 232px;
            height: 36px;
            border-radius: 188px;
            background-color: #aacdf9;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            &:hover {
              box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
              background-color: #117aff !important;
            }
          }
          .button-diabled {
            cursor: not-allowed;
            background-color: #c8c9cc !important;
          }
        }
      }
    }
    .tip-text {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px auto;
      color: #7f7f7f;
    }
  }
  .curriculum-info-card-list {
    display: flex;
    flex-flow: row wrap;
    width: 1000px;
    margin: 0 auto;
    color: #5c9df8;
    .list-item {
      width: 231px;
      height: 376px;
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 20px;
      transition: all 0.5s;
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        .item-main {
          background-image: linear-gradient(0deg, #d6fde8 0%, #dfe9f7 100%);
        }
      }
      .item-img {
        width: 800px;
        height: 160px;
      }
      .item-main {
        width: 100%;
        height: 216px;
        background-color: #f1f7ff;
        padding: 15px 10px 10px;
        .main-title {
          margin-bottom: 10px;
          font-size: 12px;
          font-weight: bold;
        }
        .main-desc {
          height: 34px;
          line-height: 17px;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          word-break: break-all;
          /*！autoprefixer: off */
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          /*！autoprefixer: on */
          margin-bottom: 20px;
        }
        .main-tags {
          width: 100%;
          display: flex;
          overflow: hidden;
          margin-bottom: 15px;
          .tags-item {
            flex: none;
            height: 22px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            padding: 2px 8px;
            border-radius: 304px;
            margin-right: 9px;
          }
        }
        .button {
          width: 135px;
          height: 35px;
          background-color: #aacdf9;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 188px;
          font-size: 14px;
          color: #fff;
          &:hover {
            background-color: #117aff !important;
          }
        }
        .operation {
          margin-top: 10px;
          display: flex;
          align-items: center;
          .iconfont {
            font-size: 12px;
            color: #5c9df8;
            margin: 0 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.table-pagination {
  width: 1000px;
  margin: 20px auto 0;
  margin-bottom: 20px;
}
</style>
<style lang="scss">
.curriculum-tooltip {
  max-width: 500px !important;
}
</style>
